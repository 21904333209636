.paymentoptionsListHeader {
  background-color: #fff;
  letter-spacing: 2px;
  padding: 50px;
  padding-bottom: 0px;
}

.createPaymentoptionsBtn {
  background-color: #3e4555 !important;
  font-size: 12px;
}

.returnBtn {
  display: inline-block;
  border: solid 1.5px #e7e8e9;
  color: #999ca4;
  margin-top: 5;
}

.leftSpacingBtn {
  margin-left: 10px;
}


.paymentoptionsListTable {
  background-color: #fff;
  letter-spacing: 2px;
  padding-left: 0px;
  padding-right: 0px;
}

.paymentoptionsListCore {
  padding-left: 0px;
  padding-right: 0px;
}

