.advanced-search-page > div {
    background-color: #3e4555;
    height: 100%;
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
}

.advanced-search-field,
.advanced-search-field-long {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    width: 100%;
    color: #fff;
}
.date-input {
    display: flex;
    flex-direction: column;

    .date-picker-range {
        svg {
            fill: white;
        }

        .MuiInputBase-root
            .MuiInput-root
            .MuiInput-underline
            .makeStyles-input-33
            .MuiInputBase-formControl
            .MuiInput-formControl:hover {
            border-bottom: none;
        }
        .MuiInput-underline.Mui-error:after {
            border-bottom: 1px solid white;
        }
        .MuiFormHelperText-root.Mui-error {
            display: none;
        }
        .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd::before {
            border-bottom: 1px solid white;
        }
    }
}

.advanced-search-field-date {
    color: #fff;
    text-transform: uppercase;
}

.advanced-search-header {
    color: #999ca4;
    font-size: 24px;
    font-weight: bold;
    line-height: 0.87;
    letter-spacing: -1.25px;
    padding-bottom: 20px;
}

.input-group {
    padding-bottom: 16px;

    label {
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 2.8px;
    }
}

.search-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.advanced-search-submit,
.advanced-search-export {
    background-color: transparent;
    width: 48%;
    height: 35px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    border: 1px solid #fff;
    margin-top: 14px;
}

.advanced-search-export {
    background-color: #999ca4;
    border: none;
}
