.admin-loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
        width: 80px;
        height: 80px;
    }

    p {
        font-size: 18px;
        font-weight: 900;
        margin: 8px;
    }
}
