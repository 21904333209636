.return-icon {
    object-fit: contain;
    max-width: 24px;
    max-height: 24px;
    margin-bottom: 10px;
    vertical-align: 0;
}

.order-item-detail__original-price {
    text-decoration: line-through;
}

.order-item-detail__image {
    grid-column: 1;
    vertical-align: top;
    margin-left: 2px;

    small {
        display: inline-block;
    }
}

.action-checkbox-container {
    border: none;
    padding: 0;
}

.action-checkbox {
    margin-right: 10px;
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
}

.action-checkbox-active {
    cursor: pointer;
}

.action-checkbox-label {
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.status-table-data {
    width: 0px;
}

.order-totals-row {
    font-size: 14px;
}

.order-totals-row td {
    padding-right: 10px;
}

.order-item-table thead td,
.order-title {
    text-transform: uppercase;
}
