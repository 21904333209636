.page-403 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    h1 {
        font-size: 64px;
        margin: 0px;
    }
    .material-icons {
        font-size: 64px;
    }
}
