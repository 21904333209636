.deliveryoptions-list-header {
    background-color: #fff;
    letter-spacing: 2px;
    padding: 50px;
    padding-bottom: 0px;
}

.deliveryoptions-list-table {
    background-color: #fff;
    letter-spacing: 2px;
    padding-left: 0px;
    padding-right: 0px;

    .deliveryoptions-list-core {
        padding-left: 0px;
        padding-right: 0px;

        /* entire material table */
        .MuiPaper-elevation2 {
            box-shadow: none;
        }

        .inactive-btn {
            background-color: #f7f7f7;
            color: #999ca4;
        }

        .active-btn {
            background-color: #4bd4b0;
            color: #fff;
        }

        .status-btn {
            width: 135px;
            height: 35px;
            font-size: 12px;
            font-weight: bold;
            border: none;
        }

        /* material table header */
        /* .MuiTableSortLabel-root { */
        th.MuiTableCell-root {
            font-weight: bold;
            color: #3e4555;
            letter-spacing: 2.8px;
            font-size: 16px;
            border-bottom: 1px solid #e7e8e9;
            position: unset;
        }

        .MuiTableCell-root {
            font-family: 'Gotham SSm A', sans-serif;
        }
    }
}

.left-spacing-btn {
    margin-left: 10px;
}

.right-spacing-btn {
    margin-right: 10px;
}

.create-deliveryoptions-btn {
    background-color: #3e4555 !important;
    font-size: 12px;
}

.return-btn {
    display: inline-block;
    border: solid 1.5px #e7e8e9;
    color: #999ca4;
    margin-top: 5;
}
