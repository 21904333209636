.dashboard-page {
    padding: 32px;
    height: calc(100vh - 64px);
    background-color: #535a6b;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 16px;
}

.dashboard-grid__menu {
    background: #3e4555;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    height: 200px;

    &:hover {
        background-color: rgba(62, 69, 85, 0.8);
    }

    h4 {
        font-size: 24px;
        font-weight: bold;
        margin-top: 90px;
        position: absolute;
    }
    .dashboard-icon {
        width: 50px;
    }
}
