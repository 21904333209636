// Fonts
@font-face {
    font-family: 'Gotham SSm A';
    src: url('assets/fonts/F4715F49B60D9B144.woff2') format('woff2'),
        url('assets/fonts/F4715F49B60D9B144.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham SSm A';
    src: url('assets/fonts/725262BC71949F842.woff2') format('woff2'),
        url('assets/fonts/725262BC71949F842.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    /* font-family: 'Gotham SSm A'; */
    font-family: 'Gotham';
    src: url('assets/fonts/B5416F0FED9EA9CD2.woff2') format('woff2'),
        url('assets/fonts/B5416F0FED9EA9CD2.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

html,
body,
.full-height {
    height: 100%;
}

main.full-height > * {
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Gotham SSm A', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    text-decoration: none;
}

:focus {
    outline: none;
}
