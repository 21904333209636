.order-search-form {
    background-color: #535a6b;
    height: calc(100vh - 64px);
    padding-left: 0px;
    padding-right: 0px;
    overflow-x: hidden;

    .row {
        height: 100%;
    }
}
