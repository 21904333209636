.brand-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#permissions label {
    /* There's a bootstrap rule adding .5rem margin-bottom to label elements
    We'll use this so that the checkboxes align with the labels */
    margin-bottom: 0;
}

#all-permissions {
    margin-left: -10px;
}
