.order-detail {
    background: #f7f7f7;
    padding: 0px 20px;
    height: 100%;
    overflow-y: auto;
    color: '#3e4555';
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
}

.order-detail-header {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 360px;

    .right-header {
        font-weight: bold;
        .order-header {
            font-size: 12px;
            letter-spacing: 2.4px;
            color: #999ca4;
        }
        .order-number {
            font-size: 24px;
            line-height: 1.08;
        }
    }
}

.order-detail-card {
    margin-bottom: 20px;
    background: #fff;
    padding: 15px 0px 5px 0px;
    max-width: 360px;
    font-size: 14px;
    border-radius: 5px;
    box-shadow: none;

    h3 {
        text-transform: uppercase;
        font-weight: bold;
        margin-left: 12px;
        font-size: 14px;
        letter-spacing: 2.8px;
        padding-bottom: 0px;
    }

    .order-total-table th,
    .refund-summary-table th {
        width: 145px;
    }

    table tbody {
        margin: 8px;
        line-height: 1.29;
        padding-top: -10px;

        .order-sidebar-discount-tr {
            th,
            td {
                color: #f96854;
            }
        }

        th {
            text-align: left;
            border-top: none;
            padding-bottom: 0px;
            width: 145px;
            letter-spacing: normal;
            font-weight: 500;
        }
        td {
            word-break: break-all;
            border-top: none;
            padding-bottom: 0px;
        }
    }
}

.order-detail-card-actions {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
