.drawer-app-content {
    flex: auto;
    overflow: auto;
    background-color: #dfdfdf;

    .mdc-top-app-bar--fixed-adjust {
        height: 100%;
        background-color: #fff;
    }
}

.orders-search-bar {
    display: none;
}

.radio-button-container {
    position: relative;
    top: 5px;

    input {
        margin-left: 10px;
    }

    label {
        padding-left: 2px;
    }
}
