.order-view {
    // display: grid;
    // grid-template-columns: 1fr 3fr;
    // grid-template-rows: 100%;
    // height: 100%;
    display: flex;
    flex-direction: row;
}

.order-scrollable-panel {
    overflow-y: auto;
}

.order-page-wrapper {
    flex: 1;
    overflow-y: scroll;
    background-color: #fff;
}
