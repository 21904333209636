.order-status-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    overflow: hidden;
}

.order-status-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 600px;
    height: 570px;
    background: white;
    color: #3e4555;
    display: flex;
    flex-direction: column;

    .modal-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: none;
        padding: 40px 0 10px 0;
        margin: 0px 40px;
        border-bottom: 1px solid #e7e8e9;

        .title {
            letter-spacing: 2.8;
            font-size: 14px;
            margin-bottom: 0;
        }
    }

    .close {
        cursor: pointer;
        padding: 0;
        margin: 0;
    }

    .content {
        overflow: scroll;
        margin: 0 40px;
        height: 420px;

        .update {
            font-size: 14px;
            padding: 30px 0;
            border-bottom: 1px solid #e7e8e9;

            .line-wrapper {
                display: flex;
                padding-bottom: 8px;

                &:last-child {
                    padding-bottom: 0;
                }
            }
            .title {
                width: 190px;
                font-weight: 500;
            }

            .more-info {
                padding-top: 10px;
                .heading {
                    align-items: center;
                    cursor: pointer;

                    .open-close {
                        border: 1px solid #3e4555;
                        border-radius: 50%;
                        margin-left: 10px;
                        width: 16px;
                        height: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .cancel-order-btn {
        margin-top: 30px;
        margin-right: 40px;
        align-self: flex-end;
        border-radius: 5px;
        width: 165px;
        font-size: 14px;
        border: none;
        color: #fff;
        background-color: #3e4555;
    }
}

.medium {
    font-weight: 500;
}
