.login-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #000;
    align-items: center;
    justify-content: center;
}

.login-paper {
    margin: 30px 0px;
    padding: 32px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.28);
    min-width: 420px;
}

.login-form {
    display: flex;
    flex-direction: column;
    .mdc-text-field {
        margin-top: 16px;
    }
}

.login-button {
    margin-top: 16px;
}

.forgot-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 16px;
    .link-button {
        color: #405772;
        margin-left: 8px;
        font-size: 16px;
    }
}
