.refund-products-search-modal {
    padding: 30px;
    width: 700px;
    border-radius: 20px;
}

.header-text {
    font-weight: bold;
    letter-spacing: 2.8px;
}

.refund-products-search-modal-close {
    display: none;
}

.refund-products-radio {
    display: inline-block;
    width: 20px;
    height: 44px;
    margin-right: 10px;
}

.left-text {
    font-weight: bold;
}

.right-text {
    text-align: right;
}

.refund-products-label {
    width: 95%;
}
