.returnBtn {
  border: solid 1.5px #e7e8e9;
  color: #999ca4;
  margin-top: 5px;
}

.cancelPaymentoptionsBtn {
  background-color: #fff !important;
  width: 165px;
  color: #999ca4 !important;
}

.cancelPaymentoptionsBtn span {
  text-transform: none;
}

.deletePaymentoptionsBtn {
  background-color: #ff0000 !important;
  width: 165px;
}

.deletePaymentoptionsBtn span {
  text-transform: none;
}

.savePaymentoptionsBtn {
  background-color: #4bd4b0 !important;
  width: 165px;
}
.savePaymentoptionsBtn span {
  text-transform: none;
}
