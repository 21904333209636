.sub-order {
    background: #c0c0c0;
    height: calc(100% - 48px);
    overflow: auto;
    border-radius: 0px;
}

.sub-order-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
}

.sub-order-subheader__order-details {
    margin: 0px 8px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid lightgray;
    padding: 8px;
    p {
        margin: 0px;
    }
}

.sub-order-subheader__order-status {
    margin: 0px 8px;
    border-radius: 4px;
    background: #40b29e;
    padding: 8px;
    p {
        margin: 0px;
    }
    color: #fff;
}

.sub-order-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0px 8px 8px 8px;
}

.order-page {
    padding: 30px;

    #selected_facia {
        font-weight: bold;
        font-size: 10px;
        height: 30px;
    }

    .save-changes-success {
        background-color: #4bd4b0 !important;
        color: #fff !important;
        width: 135;
        height: 35;
        font-size: 12;
        font-weight: bold;
        color: white;
        border: none;
    }

    .cancel-order-btn,
    .issue-refund-btn,
    .issue-refund-return-btn,
    .save-changes-btn {
        border-radius: 5px;
        margin-left: 20px;
        width: 165px;
        font-size: 14px;
        border: none;
        color: #fff;
    }

    .cancel-order-btn,
    .issue-refund-btn,
    .issue-refund-return-btn {
        color: #fff;
    }

    .cancel-order-btn {
        background-color: #3e4555;
    }

    .issue-refund-btn {
        background-color: #ff6633;
        width: 151px;
    }

    .issue-refund-return-btn {
        background-color: #ff6633;
        width: 219px;
    }

    .save-changes-btn {
        background-color: #f7f7f7;
        color: #999ca4;
    }

    #order-accordion .card {
        border: none;
        box-shadow: none;

        #accordion-order-notes-header {
            cursor: pointer;
            background-color: #fff;
            padding-left: 0px;
            padding-right: 0px;
            max-height: 40px;

            .collapse-icon {
                max-width: 25px;
                margin-top: -10px;
            }
            .title {
                font-size: 14px;
                letter-spacing: 2.8px;
                font-weight: bold;
            }
        }
    }

    #accordion-order-notes-text {
        .card-body {
            padding-left: 0px;
            padding-right: 0px;

            #order-notes-table {
                .card-body {
                    padding: 20px;
                    background-color: #f7f7f7;
                    border: none;
                    border-radius: 10px;
                    margin-bottom: 15px;
                    font-size: 14px;
                    line-height: 1.29;

                    .card-text {
                        color: #3e4555;

                        .edit-note-img {
                            position: absolute;
                            margin: 0px auto;
                        }

                        .subtext {
                            margin-top: 10px;

                            .user {
                                color: #999ca4;
                            }

                            .time {
                                color: #999ca4;
                                line-height: 1.29;
                                margin-left: 5px;
                            }
                        }

                        .edit-note-img-container {
                            background-color: #f7f7f7;
                            border-right: none;
                            padding-left: -20px;
                            padding-right: -20px;
                            max-height: 38.25px;
                            border: none;
                        }
                        .edit-note-img {
                            height: 35px;
                            padding-left: 10px;
                            padding-top: 7px;
                            color: #999ca4;
                        }
                    }
                }
            }
        }

        .notes-pagination-icon {
            color: #535a6b;
            margin-left: 2px;
            cursor: pointer;
            max-height: 25px;
        }

        .notes-tools-container {
            padding-top: 15px;
            padding-bottom: 15px;

            .notes-per-page {
                display: inline-block;
                width: 140px;
                margin-left: 10px;
                margin-right: 10px;
            }
        }

        .add-note-textarea {
            border-radius: 10px;
        }

        .add-note-btn {
            color: #999ca4;
            margin-top: 18px;
        }

        .add-note-btn-disabled {
            background-color: #f7f7f7;
        }

        .add-note-btn-success {
            background-color: #4bd4b0;
            color: #fff;
        }
    }
}

.order-notes-company-logo {
    max-width: 15px;
    margin-bottom: 2px;
}

.order-item-header {
    margin-top: 48px;

    .lead {
        color: #3e4555;
        margin-left: 5px;
        font-weight: bold;
        font-size: 14px;
    }

    .order-title {
        letter-spacing: 2.8px;
    }

    .order-number {
        margin-left: 10px;
    }
}

.order-item-table {
    thead td {
        border: none;
        color: #3e4555;
        letter-spacing: 2.8px;
        font-weight: bold;
        font-size: 14px;
    }
    td {
        padding-left: 0px;
        line-height: 1.29;
        border-bottom: 1px solid #dee2e6;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #3e4555;
        font-size: 14px;

        span {
            opacity: 0.8;
        }
    }

    .order-item-name {
        font-size: 14px;
        line-height: 1.29;
        max-width: 120px;
        display: inline-block;
        padding-left: 15px;
    }

    .order-item-size {
        font-size: 12px;
    }

    .action-select {
        // color: #e7e8e9;
        width: 100%;
        border-radius: 5px;
        height: 10px;
        height: 32px;
        font-size: 14px;
    }

    td.action-1 {
        padding-left: 5px;

        select:first-child {
            margin-bottom: 8px;
        }
    }

    .action-2 {
        padding-left: 10px;
        padding-right: 5px;
    }

    .refund-exchange-actions {
        font-size: 12px;

        span {
            font-weight: 600;
        }
    }

    .refund-exchange-edit-table,
    .refund-exchange-show-table {
        th,
        td {
            border: none;
            padding-top: 0px;
            padding-left: 0px;
            // padding-right: 10px;
            // padding-bottom: 10px;
            font-size: 14px;
        }
    }

    .refund-exchange-edit-table {
        td {
            line-height: 1.29;
        }
    }
}

.mdc-top-app-bar--fixed-adjust {
    background-color: #fff;
}
