.deliverypage-container {
    background-color: #fff;
    letter-spacing: 2px;
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #3e4555;

    .inline-block {
        display: inline-block;
    }

    .return-btn {
        border: 'solid 1.5px #e7e8e9';
        color: '#999ca4';
        margin-top: 5px;
    }

    .duplicate-deliveryoptions-btn {
        background-color: #3e4555 !important;
        width: 165px;
        color: #fff !important;
    }
    .duplicate-deliveryoptions-btn span {
        text-transform: none;
    }

    .cancel-deliveryoptions-btn {
        background-color: #fff !important;
        width: 165px;
        color: #999ca4 !important;
    }
    .cancel-deliveryoptions-btn span {
        text-transform: none;
    }
    .delete-deliveryoptions-btn {
        background-color: #ff0000 !important;
        width: 165px;
    }
    .delete-deliveryoptions-btn span {
        text-transform: none;
    }
    .save-deliveryoptions-btn {
        background-color: #4bd4b0 !important;
        width: 165px;
    }
    .save-deliveryoptions-btn span {
        text-transform: none;
    }

    .active-toggle.Mui-selected {
        background-color: #4bd4b0 !important;
    }

    .active-toggle.Mui-selected span {
        color: #fff;
    }

    .deliveryoption-name-header {
        letter-spacing: -1px;
        font-weight: bold;
    }
    /* form styles */
    label {
        font-weight: bold;
    }
    .channel-inner-container {
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
    }
    .channel-item {
        width: auto;
        margin-right: 10px;
        border-radius: 3px;
        background-color: #f7f7f7;
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
    }
    .channel-item span.icon-cont svg {
        width: 13.3px;
        height: 10.3px;
        cursor: pointer;
    }
    .channel-item span.text-cont {
        font-size: 12px;
        margin-left: 4px;
        margin-top: 4px;
    }
    /* end form styles */
    .active-text {
        width: 152px;
        height: 16px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 2.4px;
        text-align: right;
    }

    .active-text {
        display: inline;
        margin-right: 10px;
    }
}
