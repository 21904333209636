$mdc-theme-primary: #37ad90;
$mdc-theme-on-primary: #fff;
$mdc-theme-secondary: #334356;
$mdc-theme-on-secondary: #fff;

$mdc-typography-font-family: 'Gotham SSm A';

@import '~@material/theme/mdc-theme';
@import '~@material/typography/mdc-typography';

@import '~@material/switch/mdc-switch.scss';
@import '~@material/form-field/mdc-form-field.scss';
@import '~@material/ripple/mdc-ripple.scss';

@import '~@material/button/mdc-button.scss';

@import '~@material/textfield/mdc-text-field.scss';
@import '~@material/floating-label/mdc-floating-label.scss';
@import '~@material/notched-outline/mdc-notched-outline.scss';
@import '~@material/line-ripple/mdc-line-ripple.scss';

@import '~@material/card/mdc-card.scss';

@import '~@material/layout-grid/mdc-layout-grid.scss';

@import '~@material/icon-button/mdc-icon-button.scss';

@import '~@rmwc/select/select.css';
@import '~@material/select/mdc-select.scss';
@import '~@material/notched-outline/mdc-notched-outline.scss';
@import '~@material/menu-surface/mdc-menu-surface.scss';

@import '~@material/checkbox/mdc-checkbox.scss';

@import '~@material/drawer/mdc-drawer.scss';

@import '~@material/list/mdc-list.scss';

@import '~@material/top-app-bar/mdc-top-app-bar.scss';

@import '~@rmwc/icon/icon.css';

@import '~@material/menu/mdc-menu.scss';

@import '~@material/tab-bar/mdc-tab-bar.scss';
@import '~@material/tab/mdc-tab.scss';
@import '~@material/tab-scroller/mdc-tab-scroller.scss';
@import '~@material/tab-indicator/mdc-tab-indicator.scss';

@import '~react-confirm-alert/src/react-confirm-alert'; // Import css

.mdc-drawer__content {
    background-color: #3e4555;
    color: #fff;
}

.mdc-drawer.mdc-drawer--open.mdc-drawer--dismissible {
    border: none;
}

.primary-color-switch {
    @include mdc-switch-toggled-on-color(#37ad90);
}

.top-margin {
    margin-top: 16px;
}

.bottom-margin {
    margin-bottom: 16px;
}

.padded {
    padding: 16px;
}

.error-message {
    background: $mdc-theme-error;
    color: $mdc-theme-on-error;
    padding: 16px;
    margin: 0px 24px 24px 24px;
}

.full-width {
    width: 100%;
}

.button-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 0px;
    .mdc-button {
        margin-right: 16px;
    }
}

.table-wrapper {
    margin: 16px 16px;
}

.link-button {
    font-family: 'Gotham SSm A', sans-serif;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;

    &.link-button:hover,
    &.link-button:focus {
        text-decoration: none;
    }
}

.header-search-name-wrapper {
    width: 100px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    pointer-events: none;

    .header-search {
        height: 36px;
        pointer-events: visible;

        &:hover {
            opacity: 1;
            background-color: #535a6b;
            border-radius: 50%;
        }
    }

    .separator {
        width: 1px;
        height: 40px;
        background-color: white;
    }

    .header-initials {
        background-color: white;
        color: #3e4555;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 900;
    }
}
.mdc-top-app-bar__action-item.header-search-name-wrapper:hover::before,
.mdc-top-app-bar__action-item.header-search-name-wrapper:active,
.mdc-top-app-bar__action-item.header-search-name-wrapper:focus {
    background-color: transparent;
    opacity: 1;
}

.orders-search-bar {
    div.input-group {
        padding-bottom: 0;
    }
}

.orders-table {
    table.table tr td {
        padding: 22px 12px;
    }
}
