.drawer-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    overflow: hidden;

    .mdc-drawer__header {
        background-color: #3e4555;
        padding-left: 30px;

        img {
            max-width: 150px;
        }
    }
}

.mdc-drawer__header img {
    width: 200px;
    margin-top: 16px;
}

.drawer-app-content {
    flex: auto;
    overflow: auto;
    background-color: #dfdfdf;

    .mdc-top-app-bar--fixed-adjust {
        min-height: 100%;
    }

    .nav-top-bar {
        .mdc-top-app-bar__title {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            padding-left: 0;
        }
    }
}

#statusModal {
    overflow: hidden;
}

.mdc-top-app-bar {
    background: #3e4555;
    .mdc-top-app-bar__title {
        color: #fff;
        font-size: 14px;
        letter-spacing: 2.8px;
    }
}

.sidenav-list {
    .mdc-list-item {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.7);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 0;
        padding: 0;
        margin: 0;
        padding-left: 30px;
        padding-right: 24px;
        font-weight: 300;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .menu-icon {
        width: 20px;
        margin-right: -5px;
    }
    .mdc-list-item.active-item.mdc-list-item--selected {
        background-color: #3e4555;
        opacity: 1;
        color: white;
    }

    .mdc-list-item--selected::before,
    .mdc-list-item--selected::after {
        background-color: #3e4555 !important;
    }
}
