.search-page {
    display: flex;
    align-items: center;
}

.search-fields-container {
    border-bottom: 1px solid #fff;
    width: 75%;
    margin-right: 25px;

    ::placeholder {
        color: #fff;
    }
}

.search-icon-container {
    background-color: '#f7f7f7';
    border-right: 'none';
    margin-left: -9px;
    border: 'none';
}

.search-icon-image {
    color: #fff;
}

.search-page-order-number {
    background-color: transparent;
    border: none;
    width: 100%;
    color: #fff;
}

/* 
    Chrome/Opera/Safari 
    Firefox 19+
    IE 10+
    Firefox 18-
*/
.order-number::placeholder {
    color: #fff;
}

.search-header {
    color: #999ca4;
    font-size: 50px;
    font-weight: bold;
    line-height: 0.75;
    letter-spacing: -1.33px;
    padding-bottom: 20px;
}

.search-submit {
    background-color: #4bd4b0;
    width: 145px;
    height: 35px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    border: none;
    margin-top: 14px;
}
